<template>
  <div>
    <validation-observer ref="validMitra">
      <b-card title="Check Mitra">
        <b-row>
          <b-col
            cols="12"
            md="6"
          >
            <b-form-group
              label="NIK"
              label-for="input-1"
            >
              <validation-provider
                #default="{ errors }"
                name="nik_ktp"
                rules="required"
              >
                <b-form-input
                  v-model="nik_ktp"
                  :state="errors.length > 0 ? false:null"
                />
              </validation-provider></b-form-group>
            <b-button
              variant="success"
              @click="checkMitra()"
            >
              <span v-if="!loading">Check</span>
              <b-spinner
                v-else
                small
              />

            </b-button>
          </b-col>
        </b-row>
      </b-card>
    </validation-observer>
  </div>
</template>
<script>
// import AppBreadcrumb from '@core/layouts/components/AppBreadcrumb.vue'
import ToastificationContent from
'@core/components/toastification/ToastificationContent.vue'

import {
  BCard, BCol, BRow, BButton, BFormGroup, BFormInput, BSpinner,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import { db } from '@/firebase'

export default {
  components: {
    // AppBreadcrumb,
    BCard,
    BCol,
    BRow,
    BButton,
    BFormGroup,
    BFormInput,
    BSpinner,
    ValidationProvider,
    ValidationObserver,

  },
  data: () => ({
    loading: false,
    nik_ktp: '',
    required,

  }),
  methods: {
    checkMitra() {
      this.$refs.validMitra.validate()
        .then(success => {
          if (success) {
            //
            db.collection('appConfig')
              .doc('web_hub')
              .collection('blacklist')
              .where('nik_ktp', '==', this.nik_ktp, true)
              .get()
              .then(res => {
                // console.log(res)
                if (!res.empty) {
                  this.$swal({
                    title: 'NIK ini telah diblokir',
                    // text: 'Konfirmasi jika anda sudah yakin',
                    icon: 'warning',
                    showCancelButton: false,
                    confirmButtonText: 'Keluar',
                    cancelButtonText: 'Batalkan',
                    customClass: {
                      confirmButton: 'btn btn-outline-danger', cancelButton: 'btn btn-outline-danger ml-1',
                    },
                    buttonsStyling: false,
                  }).then(result => {
                    if (result.value) {
                      this.$toast({
                        component: ToastificationContent,
                        props: {
                          title: 'NIK mitra ini telah diblokir !!',
                          icon: 'EditIcon',
                          variant: 'danger',
                        },
                      })
                    }
                  })
                } else {
                  this.$swal({
                    title: 'Daftar Mitra',
                    // text: 'Konfirmasi jika anda sudah yakin',
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Lanjutkan',
                    cancelButtonText: 'Batalkan',
                    customClass: {
                      confirmButton: 'btn btn-success', cancelButton: 'btn btn-outline-danger ml-1',
                    },
                    buttonsStyling: false,
                  }).then(result => {
                    if (result.value) {
                      // this.$toast({
                      //   component: ToastificationContent,
                      //   props: {
                      //     title: 'NIK mitra ini telah diblokir !!',
                      //     icon: 'EditIcon',
                      //     variant: 'danger',
                      //   },
                      // })
                      this.$router.push('mitra/tambah')
                    }
                  })
                }
              })
          }
        })
    },
  },
}
</script>
